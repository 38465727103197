.DisclaimerContent{
    margin-top: 3rem;
   
}
h2{
    text-decoration: underline;
}
.informationDisclaimer{
    text-align: start;
    padding: 22px;
}
.informationDisclaimer p{
font-size: 14px;
font-weight: 500;
color: grey;
}