.contentTerms{
    margin-top: 3.7rem;
}
.contentTerms h2{
    text-decoration: underline;
}
.content p{
font-size: 14px;
text-align: start;
font-weight: 500;
}
.content{
    padding:20px;
  
}
.content h3{
    text-align: start;
    font-weight: 800;
}