.privacycontent{
    margin-top: 3rem;
    padding: 1.3rem;
}
.privacycontent p{
    text-align: start;
    font-size: 15px;
    font-weight: 500;
    
}
.privacycontent h3{
    text-align: start;
    color: red;
    margin-top: 1rem;
}
.privacycontent h2{
 text-decoration: underline;
    color: red;

}
