

  .modaldiv {
   
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(11, 11, 11, 0.5);
    display: grid;
    place-items: center;
    z-index: 21345678;
  }
  .cardbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border: 2px solid white;
    padding: 20px;
    border-radius: 20px;
    background: #8e8e7c;
    width: 307px;
  }
  .erroriconbox,
  .mainerrorclosebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
  }
  .errorclosebtn {
    color: white;
    font-size: 1.4rem;
    background: #321515;
    width: 76px;
    height: 32px;
   
    text-align: center;
    border-radius: 24px;
  }
  .text-center {
    text-align: center !important;
  }
  .plusum {
    border: 1px solid rgb(137, 111, 111);
    height: fit-content;
    border-radius: 7px;
    font-size: 12px;
  }
  
  .buttoncontainer {
    display: flex;
    justify-content: space-evenly;
   
    font-size: 12px;
  }
  .headerCheck {
  
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 28rem;
  }
  .codeinput {
    display: flex;
    justify-content: space-around;
  }
  .desciptionCheck {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 29rem;
    margin-top: 1rem;
    padding: 5px;
    font-size: 1.1rem;
  }
  .searchbtn{
    width: 82px;
    color: white;
    padding: 2px;
    border: none;
    background: rgb(88, 30, 30);
    border-radius: 6px;
    margin-top: 2px;
    margin-bottom: 6px;
  }
  .checkoutbtn{
    display: flex;
    justify-content: space-evenly;
  }
  .checkoutbtn1{
  border: 1px solid #a15050;
  border-radius: 6px;
  font-size: 16px;
  color: #fffcfc;
  background: #6a3434 !important;
  }
  
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }