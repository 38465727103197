.FooterContainer{
    /* border-top: 2px solid red; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color:white;
    background: rgb(106, 52, 52);
    height: 8rem;
}
.innerFooterContainer{
   

}
.companyName{
    display: flex;
    justify-content: start;
}
.companyName p{
    margin-left: 25px;
    /* padding: 1.5rem; */
    font-weight: 700;
    margin-top: 31px;
    position: relative;
    font-size: 1.3rem;
    left: 9px;  
}
.routinPagelink{
    display: flex;
}
.routinPagelink li{
    list-style-type: none;
    cursor: pointer;
    /* font-weight: 500; */
    font-size: 14px;
    opacity: 0.7;
}
.routinPagelink li:hover{
    color:black;
    opacity:1;
}
.allRightReserved{
    background: rgb(180, 148, 148);
}
.allRightReserved p{
    font-size: 12px;
    /* color: black; */
    font-weight: 600;
    padding:10px;
    opacity:0.7;
}