.cardAlign{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.grpHead h2{
    display: flex;
    position: relative;
    top: 7px;
    font-size: 1.4rem;
    left: 55px;
    font-weight: 500;
    border-bottom: 2px solid grey;
}
.grpHead{
    margin-top: 2rem;
    /* border:2px solid red; */
}
.grpHead1  h2{
    display: flex;
    position: relative;
    top: 7px;
    font-size: 1.4rem;
    left: 55px;
    font-weight: 500;
    border-bottom: 2px solid grey;
}
.grpHead1{
    margin-top: 2rem;
}
#Appetisers{
    /* font-size: 1px; */
}