.contentfaq{
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.contentfaq h2{
    text-decoration: underline;
}
.css-ahj2mt-MuiTypography-root {
    text-align: start;
    /* color: black; */
}
.css-ta84vm-MuiAccordionDetails-root {
    padding: 8px 16px 16px;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, .125);
    background: #a09b9b;
}
.css-1fjvggn-MuiPaper-root-MuiAccordion-root:not(:last-child) {
    /* background: #503434; */
    border: 1px solid rgb(143, 139, 139);
    font-weight: 600;
    /* color: white; */
}